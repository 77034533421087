<template>
	<b-col cols="12" class="p-0">
		<b-card>
			<form @submit.prevent="saveItem">
				<b-row>
					<b-col cols="12">
						<div class="form-group">
							<label>Tahun *<span class="tooltip_hint" id="hint-1">?</span></label>
							<input 
								type="text" 
								class="form-control" 
								placeholder="2019"
								v-model="formPtkp.year"
							>
							<b-tooltip target="hint-1" triggers="hover">
								Tahun mulai berlaku penghasilan tidak kena pajak tsb
							</b-tooltip>
						</div>
					</b-col>
					<b-col cols="12">
						<div class="form-group">
							<label>Jumlah Tanggungan *</label>
							<b-form-select
								v-model="formPtkp.tax_liability"
								:options="options"
								@change="changeLiability"
							/>
						</div>
					</b-col>
					<b-col cols="6">
						<b-row>
							<template
								v-for="(item, index) in formPtkp.ptkp_tk"
							>
								<b-col 
									cols="12" 
									:key="index + 'ptkp_tk'"
								>
									<label>TK/{{ index }} </label>
									<input 
										type="text" 
										class="form-control" 
										placeholder="54000000"
										v-model="item.nominal"
									>
								</b-col>
							</template>
						</b-row>
					</b-col>
					<b-col cols="6">
						<b-row>
							<template
								v-for="(item, index) in formPtkp.ptkp_k"
							>
								<b-col 
									cols="12" 
									:key="index + 'ptkp_k'"
								>
									<label>K/{{ index }}</label>
									<input 
										type="text" 
										class="form-control" 
										placeholder="54000000"
										v-model="item.nominal"
									>
								</b-col>
							</template>
						</b-row>
					</b-col>
				</b-row>
				
				<b-col cols="12" class="p-0 mt-2">
					<button
						variant="primary"
						class="btn waves-effect waves-float waves-light btn-primary"
					>
						Save
					</button>
					&nbsp;
					&nbsp;
					<a
						@click="handleBackButton()"
						variant="secondary"
						class="btn waves-effect waves-float waves-light btn-secondary"
					>
						Cancel
					</a>
				</b-col>
			</form>
		</b-card>
	</b-col>
</template>

<script>
import {
	BCard,
	BRow, 
	BFormGroup, 
	BFormRadio,  
	BCol,
	BTooltip,
	BFormSelect
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, configure } from 'vee-validate'
import { required } from '@validations'
import { successNotification, errorNotification } from '@/auth/utils'
configure({
	classes: {
		valid: 'is-valid',
		invalid: 'is-invalid',
		dirty: ['is-dirty', 'is-dirty'], // multiple classes per flag!
		// ...
	}
})
export default {
	components: {
		BCard,
		BRow,
		BCol,
		BFormGroup,
		BFormRadio,
		BTooltip,
		BFormSelect,
		ValidationProvider,
		ValidationObserver
	},

	data() {
		return{
			required,
			formPtkp: {
				tax_liability: '',
				year: '',
			},
			options:[
				{ value: '', text: '--- Pilih Jumlah Tanggungan ---' },
				{ value: 0, text: '0' },
				{ value: 1, text: '1' },
				{ value: 2, text: '2' },
				{ value: 3, text: '3' },
				{ value: 4, text: '4' },
				{ value: 5, text: '5' },
				{ value: 6, text: '6' },
				{ value: 7, text: '7' },
				{ value: 8, text: '8' },
				{ value: 9, text: '9' },
				{ value: 10, text: '10' },
			],
		}
	},

	mounted() {
		this.loadItem()
	},

	methods: {
		handleBackButton() {
			history.back()
		},
		changeLiability(option) {
			this.formPtkp.ptkp_tk = [{}]
			this.formPtkp.ptkp_k = [{}]
			for (var i= 0; i < option; i++) {
				this.formPtkp.ptkp_tk.push({})
				this.formPtkp.ptkp_k.push({})
			}
			
		},
		async loadItem() {
			const params = this.$route.params.uuid
			await this.$http.get('admin/ptkps/' + params)
				.then(response => {
					this.formPtkp = response.data.data
				})
		},
		saveItem() {
			const params = this.$route.params.uuid
			var ptkpk = []
			var ptkptk = []
			this.formPtkp.ptkp_k.forEach(function (v, i) {
				ptkpk.push(v.nominal)
			})
			this.formPtkp.ptkp_tk.forEach(function (v, i) {
				ptkptk.push(v.nominal)
			})
			this.formPtkp.variables = {
				ptkpk: ptkpk,
				ptkptk: ptkptk
			}
			this.$http.patch('admin/ptkps/' + params, this.formPtkp)
			.then(response => {
				this.loadItem()
				successNotification(this, 'Success', 'PTKP sukses diedit!')
				this.$router.push({ name: 'ptkp' })
			}).catch(error => {
				if (error.response.data.meta.validations) {
					this.validations = error.response.data.meta.validations
					errorNotification(this, 'Kewajiban Pajak', this.validations.tax_liability.join(' '))
					errorNotification(this, 'Tahun', this.validations.year.join(' '))
				}
			})
		}
	}
}
</script>

<style scoped>
	.tooltip_hint {
		border-radius: 50%;
		background: #000;
		color: #fff;
		padding: 0px 4px;
	}
</style>